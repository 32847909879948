/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import { Hotjar } from 'nextjs-hotjar';
import '../styles/globals.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import Script from 'next/script';
import Header from '../components/layouts/Header';
import Footer from '../components/layouts/Footer';

export default function App({ Component, pageProps }) {
  useEffect(() => {
    AOS.init({
      easing: 'ease-out-cubic',
      once: true,
      offset: 50,
    });
  }, []);

  return (
    <>
      {/* <Script src="https://www.googletagmanager.com/gtag/js?id=G-SCHD5HP82M" />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-SCHD5HP82M');
        `}
      </Script> */}
      {/* <Hotjar id={1287799} sv={6} /> */}
      <main>
        <Header />
        <Component {...pageProps} />
        <Footer />
      </main>
    </>
  );
}

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};
