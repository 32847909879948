import React from 'react';
import Link from 'next/link';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { appDetails } from '../../data/appData';

export default function Footer() {
  return (
    <section className="bg-white relative z-10 px-3 md:px-5 py-10 md:py-20" id="contacto">
      <div className="grid items-center w-full max-w-xl grid-cols-1 mx-auto md:grid-cols-2 gap-x-10 md:max-w-5xl 2xl:max-w-7xl">
        <div className="mb-10 md:mb-0">
          <h2 className="mb-5 text-3xl md:mb-10 md:text-4xl font-space-grotesk uppercase">
            Exploremos Estrategias
            <br />
            {' '}
            para su Proyecto
          </h2>
          <Link
            href={appDetails.calendlyLink}
            target="_blank"
            className="flex items-center px-4 py-2 text-sm border border-black rounded-full md:text-base w-fit"
          >
            Reservar Conferencia
            <ArrowUpRightIcon className="w-4 h-4 ml-1" />
          </Link>
        </div>
        <div className="grid grid-cols-2 text-sm md:text-base max-h-[150px] text-left md:text-right">
          <div>
            <p>
              {appDetails.address}
              <br />
              {`${appDetails.city} - ${appDetails.state} ${appDetails.zipCode}`}
            </p>
          </div>
          <div className="flex flex-col gap-y-0">
            <Link target="_blank" className="pb-3" href={`tel:${appDetails.contact.phoneLink}`}>{appDetails.contact.phone}</Link>
            <Link target="_blank" className="pb-3" href={`mailto:${appDetails.contact.email}`}>{appDetails.contact.email}</Link>
          </div>
        </div>
      </div>
    </section>
  );
}
