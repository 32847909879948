import React, { useState } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';
import Image from 'next/image';
import Link from 'next/link';
import { navigation, appDetails } from '../../data/appData';

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="fixed top-3 z-50 w-full px-3 md:px-5">
      <div className="w-full max-w-xl md:max-w-5xl 2xl:max-w-7xl mx-auto bg-white/50 backdrop-blur-xl shadow-xl rounded-full px-3 border border-neutral-100/50">
        <nav
          className="grid grid-cols-4 items-center py-2 mx-auto w-full"
          aria-label="Global"
        >
          <div className="col-span-3 lg:col-span-1 flex items-center gap-x-12">
            <Link href="/" className="relative w-72 h-12">
              <Image src="/images/colmena-digital-logo.svg" fill className="object-contain object-left" alt="Logo Colmena Digital" />
            </Link>
          </div>
          <div className="col-span-2 justify-center hidden w-full bg-rw-full lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link
                key={item.name}
                href={item.href}
                className="text-base leading-6 text-neutral-900"
              >
                {item.name}
              </Link>
            ))}
          </div>
          <div className="flex lg:hidden col-span-1 justify-end">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-neutral-700"
              onClick={() => setMobileMenuOpen(true)}
              aria-label="Toggle Menu"
            >
              <Bars3Icon className="w-10 h-10" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex justify-end">
            <Link
              href={appDetails.calendlyLink}
              target="_blank"
              className="px-4 py-2 text-sm leading-6 text-white bg-neutral-900 rounded-3xl hover:bg-neutral-700 transition-all"
            >
              Reservar Conferencia
            </Link>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen} aria-label="Toggle Menu">
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-[60] w-full px-3 md:px-5 py-3 overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-neutral-900/10">
            <div className="flex items-center justify-between">
              <Link href="/" className="relative w-72 h-8">
                <Image src="/images/colmena-digital-logo.svg" fill className="object-contain object-left" alt="Logo Colmena Digital" />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-neutral-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Cerrar Menú</span>
                <XMarkIcon className="w-10 h-10" aria-hidden="true" />
              </button>
            </div>
            <div className="flow-root mt-6">
              <div className="-my-6 divide-y divide-neutral-900/10">
                <div className="py-3 space-y-2">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      onClick={() => setMobileMenuOpen(false)}
                      className="block px-3 py-2 -mx-3 text-lg leading-7 text-neutral-900 rounded-lg hover:bg-neutral-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-3">
                  <Link
                    href={appDetails.calendlyLink}
                    target="_blank"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-lg leading-7 text-neutral-900 hover:bg-neutral-50"
                  >
                    Reservar Conferencia
                  </Link>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </div>
    </header>
  );
}
