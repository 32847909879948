import {
  Globe as GlobeIcon,
  Code as CodeIcon,
  Cloud as CloudIcon,
  Image as ImageIcon,
  Link as LinkIcon,
  CheckCircle as CheckCircleIcon,
  Tool as ToolIcon,
  UploadCloud as UploadCloudIcon,
  Server as ServerIcon,
  MessageSquare as MessageSquareIcon,
  Zap as ZapIcon,
  BookOpen as BookOpenIcon,
  DollarSign as DollarSignIcon,
  Heart as HeartIcon,
  ShoppingBag as ShoppingBagIcon,
  Key as KeyIcon,
  Database as DatabaseIcon,
  Coffee as CoffeeIcon,
  Tv as TvIcon,
  Award as AwardIcon,
  CreditCard as CreditCardIcon,
  Layers as LayersIcon,
  AlertTriangle as AlertTriangleIcon,
  BarChart2 as BarChart2Icon,
  CornerRightUp as CornerRightUpIcon,
  Share2 as Share2Icon,
  HardDrive as HardDriveIcon,
  Search as SearchIcon,
  Layout as LayoutIcon,
  Clock as ClockIcon,
  Shield as ShieldIcon,
  Sliders as SlidersIcon,
  XCircle as XCircleIcon,
  Frown as FrownIcon,
  BarChart as BarChartIcon,
  Maximize2 as Maximize2Icon,
  Smartphone as SmartphoneIcon,
  Lock as LockIcon,
  Loader as LoaderIcon,
  TrendingUp as TrendingUpIcon,
  Repeat as RepeatIcon,
  PlusCircle as PlusCircleIcon,
  MessageCircle as MessageCircleIcon,
  UserCheck as UserCheckIcon,
} from 'feather-icons-react/build/IconComponents';

import {
  BugAntIcon, ShoppingCartIcon, RocketLaunchIcon,
} from '@heroicons/react/24/outline';

import WebsitesLottie from '../components/lotties/1-Websites.json';
import WebappsLottie from '../components/lotties/2-Webapps.json';
import PSDtoHTMLLottie from '../components/lotties/3-PSDtoHTML.json';
import SupportLottie from '../components/lotties/5-Support.json';
import ConsultingLottie from '../components/lotties/6-Consulting.json';

export const appDetails = {
  appName: 'Colmena Digital',
  companyName: 'Colmena Digital LLC',
  schedule: 'Lun. a Vie.: 09:30 - 17:00',
  calendlyLink: 'https://calendly.com/colmenadigital/discovery-call',
  contact: {
    phone: '+1 (786) 309-1122',
    phoneLink: '+17863091122',
    whatsapp: '+1 (786) 309-1122',
    whatsappLink: 'https://wa.me/17863091122',
    email: 'info@colmenadigital.net',
  },
  address: '9100 S Dadeland Blvd.',
  city: 'Miami',
  state: 'FL',
  zipCode: '33156',
  socialMedia: {
    facebook: 'https://www.facebook.com/ColmenaDigital',
    instagram: 'https://www.instagram.com/ColmenaDigital',
    twitter: 'https://www.twitter.com/ColmenaDigital_',
  },
};

export const navigation = [
  { name: 'Inicio', href: '#intro' },
  { name: 'Soluciones', href: '#soluciones' },
  { name: 'Visión', href: '#vision' },
];

export const solutions = [
  {
    id: 1,
    name: 'Web Apps',
    icon: CodeIcon,
  },
  {
    id: 2,
    name: 'Páginas Web',
    icon: GlobeIcon,
  },
  {
    id: 4,
    name: 'Proyectos SaaS',
    icon: CloudIcon,
  },
  {
    id: 5,
    name: 'E-commerce',
    icon: ShoppingCartIcon,
  },
  {
    id: 7,
    name: 'Integraciones',
    icon: LinkIcon,
  },
  {
    id: 8,
    name: 'Quality Assurance',
    icon: CheckCircleIcon,
  },
  {
    id: 10,
    name: 'Migrations & Deployments',
    icon: UploadCloudIcon,
  },
  {
    id: 12,
    name: 'Debugging y Mantenimiento',
    icon: BugAntIcon,
  },
  {
    id: 3,
    name: 'Conversiones a HTML',
    icon: ImageIcon,
  },
  {
    id: 16,
    name: 'Asesoría y Consultoría',
    icon: MessageSquareIcon,
  },
];

export const featuredSolutions = [
  {
    id: 1,
    name: 'Web Apps',
    description: 'Desarrollamos aplicaciones web robustas, enfocadas en funcionalidad y experiencia de usuario.',
    icon: CodeIcon,
    lottie: WebappsLottie,
  },
  {
    id: 2,
    name: 'Páginas Web',
    description: 'Diseñamos sitios web personalizados y optimizados, reflejando la esencia y metas de su marca.',
    icon: GlobeIcon,
    lottie: WebsitesLottie,
  },
  {
    id: 6,
    name: 'Asesoría y Consultoría',
    description: 'Ofrecemos asesoramiento integral en estrategias digitales y tecnologías full-stack, potenciando su proyecto.',
    icon: MessageSquareIcon,
    lottie: ConsultingLottie,
  },
  {
    id: 3,
    name: 'PSD/AI/Figma to HTML',
    description: 'Convertimos diseños gráficos en páginas web HTML, con alta fidelidad y eficiencia en diseño.',
    icon: ImageIcon,
    lottie: PSDtoHTMLLottie,
  },
  {
    id: 5,
    name: 'Debugging y Mantenimiento',
    description: 'Soluciones técnicas profesionales para resolver problemas y antipicar desafíos futuros en su sitio web.',
    icon: ToolIcon,
    lottie: SupportLottie,
  },
];

export const niches = [
  {
    name: 'Startups',
    icon: ZapIcon,
  },
  {
    name: 'Universidades',
    icon: BookOpenIcon,
  },
  {
    name: 'Negocios Financieros',
    icon: DollarSignIcon,
  },
  {
    name: 'SaaS',
    icon: CloudIcon,
  },
  {
    name: 'Clínicas y Hospitales',
    icon: HeartIcon,
  },
  {
    name: 'Hoteles',
    icon: KeyIcon,
  },
  {
    name: 'Empresas de Software B2B',
    icon: DatabaseIcon,
  },
  {
    name: 'Agencias de Marketing',
    icon: RocketLaunchIcon,
  },
  {
    name: 'Alimentos y Bebidas',
    icon: CoffeeIcon,
  },
  {
    name: 'Entretenimiento y Medios',
    icon: TvIcon,
  },
  {
    name: 'Hospitalidad',
    icon: AwardIcon,
  },
];

export const caseOfUse = [
  {
    name: 'Pasarelas de Pagos',
    icon: CreditCardIcon,
  },
  {
    name: 'Automatización de Procesos',
    icon: ToolIcon,
  },
  {
    name: 'Lógicas de Negocios Complejas',
    icon: LayersIcon,
  },
  {
    name: 'Integración y Transmisión de Data',
    icon: DatabaseIcon,
  },
  {
    name: 'E-commerce',
    icon: ShoppingBagIcon,
  },
  {
    name: 'Solución de Bugs',
    icon: AlertTriangleIcon,
  },
  {
    name: 'Optimización de Rendimiento',
    icon: BarChart2Icon,
  },
  {
    name: 'Planificación Estratégica',
    icon: CornerRightUpIcon,
  },
  {
    name: 'Microservicios',
    icon: Share2Icon,
  },
  {
    name: 'API',
    icon: HardDriveIcon,
  },
  {
    name: 'UI/UX',
    icon: LayoutIcon,
  },
];

export const commonGoals = [
  {
    name: 'Solución a Medida',
    icon: ToolIcon,
  },
  {
    name: 'Reducción de Tiempos',
    icon: ClockIcon,
  },
  {
    name: 'Ahorro en Licencias',
    icon: DollarSignIcon,
  },
  {
    name: 'Maximización de Ventas',
    icon: TrendingUpIcon,
  },
  {
    name: 'Reingeniería de Procesos',
    icon: RepeatIcon,
  },
  {
    name: 'Expansión de Negocio',
    icon: PlusCircleIcon,
  },
  {
    name: 'Mejora de UX/UI',
    icon: LayoutIcon,
  },
  {
    name: 'Automatización de Operaciones',
    icon: SlidersIcon,
  },
  {
    name: 'Análisis de Estratégico',
    icon: BarChart2Icon,
  },
  {
    name: 'Integración de Sistemas',
    icon: LinkIcon,
  },
  {
    name: 'Mejoras de Seguridad',
    icon: ShieldIcon,
  },
  {
    name: 'Escalabilidad del Negocio',
    icon: Maximize2Icon,
  },
  {
    name: 'Presencia Móvil',
    icon: SmartphoneIcon,
  },
  {
    name: 'Comunicación Efectiva',
    icon: MessageCircleIcon,
  },
  {
    name: 'Fidelización de Clientes',
    icon: UserCheckIcon,
  },
  {
    name: 'Interacción Cliente-Marca',
    icon: AwardIcon,
  },
  {
    name: 'Reducción de Costos',
    icon: CreditCardIcon,
  },
  {
    name: 'Desarrollo de MVP',
    icon: ZapIcon,
  },
];

export const commonProblems = [
  {
    name: 'Carga Lenta',
    icon: LoaderIcon,
  },
  {
    name: 'Caídas de Servidor',
    icon: ServerIcon,
  },
  {
    name: 'Diseño Obsoleto',
    icon: ClockIcon,
  },
  {
    name: 'Problemas de Posicionamiento',
    icon: SearchIcon,
  },
  {
    name: 'Problemas de Seguridad',
    icon: ShieldIcon,
  },
  {
    name: 'Bugs de Software',
    icon: BugAntIcon,
  },
  {
    name: 'Interfaz Confusa',
    icon: SlidersIcon,
  },
  {
    name: 'Integración Fallida',
    icon: XCircleIcon,
  },
  {
    name: 'Mala UX',
    icon: FrownIcon,
  },
  {
    name: 'Bajas Conversiones',
    icon: BarChartIcon,
  },
  {
    name: 'Escalabilidad Limitada',
    icon: Maximize2Icon,
  },
  {
    name: 'Datos Desordenados',
    icon: DatabaseIcon,
  },
  {
    name: 'Incompatibilidad Móvil',
    icon: SmartphoneIcon,
  },
  {
    name: 'Acceso Denegado',
    icon: LockIcon,
  },
  {
    name: 'Backup Fallido',
    icon: HardDriveIcon,
  },
];

export const hero = [
  {
    id: 2,
    name: 'Delego AI',
    description: 'Desarrollamos un portal web moderno para Delego, elevando su presencia online. Nuestra solución potenció su tráfico y posicionamiento como software inteligente para optimizar y monitorear la entrega de productos y servicios en tiempo real.',
    priority: true,
    image: '/images/hero/delego.jpg',
  },
  {
    id: 3,
    name: 'IntegraComex',
    description: 'Refrescamos por segunda vez su portal web que complementa su software de procesamiento aduanero. Nuestra solución ofrece una interfaz intuitiva y eficiente, contribuyendo a las comunicaciones con sus clientes, marketing y reflejando su innovación tecnológica.',
    priority: false,
    image: '/images/hero/integracomex.jpg',
  },
  {
    id: 4,
    name: 'Gusto Ristobar',
    description: 'Rediseñamos la identidad en línea de Gusto Ristobar, creando una experiencia web mejorada que incrementa las conversiones. Integrando un menú digital y un sistema de gestión de eventos, nuestra solución enfatiza la atmósfera entusiasta del restaurant y optimiza la interacción con los clientes.',
    priority: false,
    image: '/images/hero/gustoristobar.jpg',
  },
  {
    id: 5,
    name: 'Representaciones Diskam',
    description: 'Construimos un sitio web que refuerza su imagen como empresa farmacéutica líder en Venezuela. Nuestra solución digital facilita la promoción y comercialización de medicamentos de calidad, destacando sus alianzas estratégicas y fortaleciendo su presencia en el mercado.',
    priority: false,
    image: '/images/hero/diskam.jpg',
  },
  {
    id: 1,
    name: 'Samsung Solve for Tomorrow',
    description: 'Creamos con alumnos de Downtown Doral Charter Upper School una aplicación para la ciudad de Doral. Integrando API y un sistema de alertas a través de IoT, logramos una solución innovadora que nos llevó a ganar el premio nacional de $100k en 2020.',
    priority: false,
    image: '/images/hero/samsung.jpeg',
  },
];
